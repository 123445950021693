<template lang="">
  <ValidationProvider
    v-slot="{ errors, required }"
    :tag="`div`"
    class="grid grid-cols-12  "
    :rules="rules"
  >
    <div class="col-span-12  md:col-span-3 full-mode">
      <div class="label ">
        <input-label
          :error="errors[0]"
          :text="`${label}${required ? ' *' : ''}`"
        />
      </div>
    </div>

    <div class="responsive-mode col-span-12 my-3">
      <div class="flex justify-between items-center">
        <div class="label ">
          <input-label
            :error="errors[0]"
            :text="`${label}${required ? ' *' : ''}`"
          />
        </div>
        <div
          v-if="showEditPencil"
          class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end"
        >
          <div v-if="!getEditStatus">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handleEdit(rowId)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6">
      <template v-if="type === 'richselect'">
        <t-rich-select
          ref="inputRef"
          placeholder="Select one"
          :options="options"
          valueAttribute="value"
          textAttribute="text"
          :hideSearchBox="isSearchBox"
          v-on="getListeners"
          :variant="{
            readonly: getDisableStatus,
          }"
          :disabled="getDisableStatus"
          v-model="valueProxy"
        />
      </template>
      <template v-else>
        <input
          ref="inputRef"
          class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
          :class="{
            'border-gray-400 placeholder-gray-500': !errors[0],
            'border-red-600 placeholder-red-500': errors[0],
            'bg-oLightestGray text-oLightGray placeholder-oLightGray': getDisableStatus,
            'has-value': hasValue,
          }"
          v-on="getListeners"
          :type="typeProxy"
          :disabled="getDisableStatus"
          :placeholder="placeholder"
          v-model="valueProxy"
        />
      </template>

      <p
        v-if="showDescription"
        :class="{ 'text-red-500': errors[0] }"
        class="description mb-2"
      >
        {{ description }}
      </p>
      <div v-if="getEditStatus" class="flex mt-3 mb-4">
        <anchor-button @click="handleCancel" text="Cancel" class="w-1/5 mr-2" />
        <anchor-button
          @click="handleSave"
          text="Save"
          variant="success"
          class="w-1/5 ml-2"
        />
      </div>
    </div>

    <div
      v-if="showEditPencil"
      class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end full-mode"
    >
      <div v-if="!getEditStatus">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="handleEdit(rowId)"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{2}"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import AnchorButton from '@/components/form/AnchorButton'
import InputLabel from '@/components/form/InputLabel'
import { EventBus } from '@/utils/EventBus'
const validTypes = [
  'url',
  'text',
  'email',
  'search',
  'number',

  'date',

  'textarea',

  'tel',
  'password',
  'richselect',
]
export default {
  name: 'SmartAppInput',
  props: {
    rowId: {
      type: [String, Number],
      default: 'AppInputId',
    },
    showEditPencil: {
      type: Boolean,
      default: true,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return validTypes.includes(value)
      },
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSearchBox: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
    },
  },
  created() {
    this.previousData = this.valueProxy
  },
  data() {
    return {
      typeProxy: '',
      valueProxy: '',
      editableStatus: false,
      previousData: null,
    }
  },

  components: {
    AnchorButton,
    InputLabel,
  },
  computed: {
    hasValue() {
      return !!this.valueProxy
    },
    getDisableStatus() {
      if (this.editableStatus) {
        return false
      } else {
        return true
      }
    },
    getEditStatus() {
      if (this.editableStatus) {
        return true
      } else {
        return false
      }
    },

    getListeners() {
      return Object.assign({}, this.$listeners, {
        // handle custom event as well as a fallback to the native events
        input: (event) => {
          const e =
            this.type === 'richselect' || this.type === 'tel'
              ? event
              : event.target.value
          this.$emit('input', e)
        },
      })
    },
  },
  watch: {
    value: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.valueProxy) {
          this.valueProxy = val
        }
      },
    },

    type: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.typeProxy) {
          this.typeProxy = val
        }
      },
    },
    isEdit: {
      deep: false,
      immediate: true,
      handler(status) {
        if (status) {
          this.editableStatus = true
        } else {
          this.editableStatus = false
          if (this.previousData !== null) {
            this.$emit('input', this.previousData)
          }
        }
      },
    },
  },
  methods: {
    handleCancel() {
      this.editableStatus = !this.editableStatus
      if (this.previousData !== null) {
        this.$emit('input', this.previousData)
      }
    },
    handleSave() {
      this.editableStatus = !this.editableStatus
      this.$emit('save', this.valueProxy)
    },
    handleEdit(id) {
      EventBus.$emit('open-edit', id)
      this.editableStatus = !this.editableStatus
    },
    focus() {
      this.$refs.inputRef.focus()
    },
  },
}
</script>
<style lang="scss">
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.description {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
