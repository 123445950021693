<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <!-- Email Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <div>
        <div>
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-mobile-alt text-22px text-oPurple"></i>
            <span class="pl-3">Rider app Version update</span>
          </div>
          <p class="break-words title-description mt-1">
            If you want to your current Riders to download latest version
            forcefully or get notified about new app version then you can change
            following settings.
          </p>
        </div>
      </div>
      <div :key="`riderApp_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in riderAppSettings">
          <SmartAppInput
            :key="itemIndex"
            :rowId="`riderApp_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="riderApp[`${item.variable}`]"
            @save="onSave(`${item.variable}`, $event)"
          />
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { EventBus } from '@/utils'
export default {
  name: 'EmailSettings',
  components: { SmartAppInput },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      riderApp: {
        app_latest_version: null,
        app_min_version: null,
        soft_update_title: null,
        soft_update_description: null,
        hard_update_title: null,
        hard_update_description: null,
      },
      riderAppSettings: [
        {
          type: 'text',
          label: 'App Latest Version',
          placeholder: `e.g  1.0.28`,
          description:
            'In your official Google and Play store current downloadable version is called the app latest version.',
          variable: 'app_latest_version',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Required Min. Version',
          placeholder: `e.g  1.0.28`,
          description: `It is the Min Version your rider has installed and you want to push them for updating latest version.`,
          variable: 'app_min_version',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Soft Update Title',
          placeholder: `e.g  Title`,
          description: `Provide the Notification Title which will be shown up to POP up title. (e.g A New Update is available to download).`,
          variable: 'soft_update_title',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Soft Update Description',
          placeholder: `e.g  Description`,
          description: `Provide description which will be shown up to POP Up description. (e.g Please download latest version of the app to avail latest updates of the app and better performance.`,
          variable: 'soft_update_description',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Hard Update Title',
          placeholder: `e.g  Title`,
          description: `Provide the Notification Title which will be shown up to POP up title. (e.g Update your current version).`,
          variable: 'hard_update_title',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Hard Update Description',
          placeholder: `e.g  Description`,
          description: `Provide description which will be shown up to POP Up description.  (e.g Please download latest version of the app otherwise you will not be able to use current version of the app).`,
          variable: 'hard_update_description',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    endpoint() {
      return `/dashboard/organizations/${this.orgId}/app-update-settings/`
    },
  },
  async created() {
    this.$http
      .get(this.endpoint)
      .then(({ data }) => {
        const formatted = {}
        for (const k in data) {
          formatted[k] = data[k] === null ? '' : data[k]
        }
        this.riderApp = { ...formatted }
        this.isLoaded = true
      })
      .catch((err) => {
        console.log('riderAppSettingsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'riderApp') {
        this.riderAppSettings = this.riderAppSettings.map((item, itemIndex) => {
          if (id === `riderApp_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
      }
    })
  },
  methods: {
    async onSave(key, val) {
      let toastTitle, toastText

      if (key === 'app_latest_version') {
        toastTitle = `App Latest Version`
        toastText = `App Latest Version has been updated`
      }
      if (key === 'app_min_version') {
        toastTitle = `Required Min. Version`
        toastText = `Required Min. Version has been updated`
      }
      if (key === 'soft_update_title') {
        toastTitle = `Soft Update Title`
        toastText = `Soft Update Title has been updated`
      }
      if (key === 'soft_update_description') {
        toastTitle = `Soft Update Description`
        toastText = `Soft Update Description has been updated`
      }
      if (key === 'hard_update_title') {
        toastTitle = `Hard Update Title`
        toastText = `Hard Update Title has been updated`
      }
      if (key === 'hard_update_description') {
        toastTitle = `Hard Update Description`
        toastText = `Hard Update Description has been updated`
      }

      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(this.endpoint, data)
        .then((res) => {
          console.log(res)
          this.riderAppSettings = this.riderAppSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
